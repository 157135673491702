/* ######### FONT STACKS ######### */
/* oswald-300 - latin */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 300;
  src: local("oswald"),
    url("./fonts/oswald-v48-latin-300.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/oswald-v48-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* oswald-regular - latin */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  src: local("oswald"),
    url("./fonts/oswald-v48-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/oswald-v48-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* oswald-700 - latin */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  src: local("oswald"),
    url("./fonts/oswald-v48-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/oswald-v48-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

:root {
  /* COLORS */
  --white: hsl(0, 0%, 100%);
  --grey: hsl(0, 0%, 50%);
  --black: hsl(0, 0%, 0%);
  --fill: #222;
  --linkHover: #1b4698;

  --color1: #eeece8;
  --color2: #c4bfb8;
  --color3: #333533;
  --color4: #202020;
  --color5: #3061ff;
  --color6: #ffbe03;
  --color7: #0d96d6;
  --color6: #ff8c00;

  /* SIZES */
  --p0: clamp(0.69rem, calc(0.5rem + 0.99vw), 1.5rem);
  --p1: clamp(0.75rem, calc(0.48rem + 1.34vw), 0.85rem);
  --p2: clamp(0.5rem, calc(0.32rem + 0.45vw), 0.91rem);
  --h4: clamp(0.75rem, calc(0.6rem + 0.73vw), 1.13rem);
  --h3: clamp(1.1875rem, calc(0.8661rem + 1.6071vw), 2.3125rem);
  --h1: clamp(2.07rem, calc(1.1rem + 4.89vw), 4.68rem);
  --size1: clamp(1.75rem, calc(0.9107rem + 4.1964vw), 4.6875rem);
  --title1: max(2.5vw, 1.2rem);

  /* WEIGHTS */
  --reg: 300;
  --bold: 700;

  /* SHADOWS */
  --textInset: 2px 3px 4px #fff, 0 0 0 #000;
  --shadow1: 0px 2px 1px -1px #0002, 0px 1px 1px 0px #00000014,
    0px 1px 3px 0px #00000012;
  --shadow2: 0px 0px 10px #0005;
  --shadow3: 0 0.3125rem 0.625rem #0007;
  --shadow4: 0rem 0px 1rem var(--color6);
  --shadow5: 0.1rem 2px 0.2rem #0009;
  --outline: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000;

  /* BLUR */
  --blur: 5px;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

body {
  font-family: "Oswald", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  min-height: 100vh;
  opacity: 0;
  animation: FCP 1500ms ease-in forwards;
  overflow-x: hidden;
}

.darkModeOn section,
.darkModeOn *::after,
.darkModeOn *::before,
.darkModeOn svg {
  fill: #0d96d6;
}

a {
  text-decoration: none;
  cursor: pointer;
}

li {
  list-style: none;
}

.bottomSig {
  display: flex;
  justify-content: center;
}

.plussign {
  padding: 0 0.75rem;
}

/***** ------- ANIMATIONS ------- *****/

@keyframes FCP {
  100% {
    opacity: 1;
  }
}
